import { IconButton, Input, Row, expandBorderRadii } from "@gadgetinc/widgets";
import { AccordionExpandIcon } from "@gadgetinc/widgets/src/icons/AccordionExpandIcon";
import { CheckmarkIcon } from "@gadgetinc/widgets/src/icons/CheckmarkIcon";
import { EditIcon } from "@gadgetinc/widgets/src/icons/EditIcon";
import { PlusIcon } from "@gadgetinc/widgets/src/icons/PlusIcon";
import { useStyletron } from "baseui";
import { StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import type { InitialBillingPlanProps, TeamProps } from "state-trees/src/Team";
import useFetch from "use-http";
import { useLocation } from "wouter";
import { asyncSafeEventHandler } from "../../lib/utils";
import { SubHeading } from "../SubHeading";
import { Divider } from "../edit/shared/Divider";
import { Breakpoint } from "./AuthLayout";
import { BillingPlanTag } from "./Billing/BillingPlanTag";
import { CreateNewTeamModal } from "./CreateNewTeamModal";

const useEditTeamName = (team: TeamProps) => {
  const [newTeamName, setNewTeamName] = useState(team.name);

  const { loading, post: updateTeam } = useFetch<{ team: TeamProps }>(`/auth/api/team/${team.id}`);

  const save = async () => {
    const result = await updateTeam({ name: newTeamName });

    if ("team" in result) {
      return { team: result.team as TeamProps };
    }
  };

  const onChange = useCallback((e: React.FormEvent) => setNewTeamName((e.target as any).value), [setNewTeamName]);

  return {
    value: newTeamName,
    submitting: loading,
    onChange,
    save,
  };
};

const EditTeamNameInput = (props: { value: string; onChange: (event: React.FormEvent) => void; onSubmit: () => Promise<void> }) => {
  return (
    <Input
      autoFocus
      placeholder="Edit team name"
      value={props.value}
      onChange={props.onChange}
      onKeyDown={asyncSafeEventHandler(async (event) => {
        if (event.key === "Enter") await props.onSubmit();
      })}
    />
  );
};

export const TeamDropdown = observer(
  (props: { team: TeamProps; otherTeams: TeamProps[]; initialPlan: InitialBillingPlanProps; readonly?: boolean }) => {
    const { otherTeams, initialPlan } = props;
    const [css, $theme] = useStyletron();
    const [team, setTeam] = useState(props.team);
    const [menuOpen, setMenuOpen] = useState(false);
    const [newTeamModalOpen, setNewTeamModalOpen] = useState(false);
    const [editingTeamName, setEditingTeamName] = useState(false);

    const editTeamName = useEditTeamName(team);

    const [location] = useLocation();

    const onEditTeamName = async () => {
      const result = await editTeamName.save();

      if (result) {
        setTeam(result.team);
      }

      setEditingTeamName(false);
    };

    return (
      <>
        <Row $gap={$theme.sizing.scale200} $style={{ width: "280px" }}>
          {editingTeamName ? (
            <EditTeamNameInput value={editTeamName.value} onChange={editTeamName.onChange} onSubmit={onEditTeamName} />
          ) : (
            <StatefulPopover
              onOpen={() => setMenuOpen(true)}
              onClose={() => setMenuOpen(false)}
              placement={PLACEMENT.bottomRight}
              popoverMargin={0}
              content={({ close }) => (
                <StatefulMenu
                  overrides={{
                    Option: {
                      style: {
                        paddingTop: `calc(${$theme.sizing.scale200} - 1px)`,
                        paddingBottom: `calc(${$theme.sizing.scale200} - 1px)`,
                        color: $theme.colors.primaryA,
                      },
                    },
                    ListItem: {
                      style: ({ $disabled }: { $disabled: boolean }) => ({
                        paddingTop: `calc(${$theme.sizing.scale200} - 1px)`,
                        paddingBottom: `calc(${$theme.sizing.scale200} - 1px)`,
                        cursor: $disabled ? "default" : "pointer",
                      }),
                    },
                  }}
                  items={[
                    ...otherTeams.map((team) => ({
                      label: team.name,
                      onSelect: () => (window.location.href = `/auth/team/select?teamId=${team.id}&redirect=${location}`),
                    })),
                    ...(otherTeams.length > 0 && !props.readonly
                      ? [
                          {
                            label: <Divider />,
                            disabled: true,
                          },
                        ]
                      : []),
                    ...(!props.readonly
                      ? [
                          {
                            label: (
                              <Row $gap={$theme.sizing.scale400}>
                                <PlusIcon />
                                Create new team
                              </Row>
                            ),
                            onSelect: () => {
                              setNewTeamModalOpen(true);
                              close();
                            },
                          },
                        ]
                      : []),
                  ]}
                  onItemSelect={({ item }) => {
                    item.onSelect();
                  }}
                />
              )}
            >
              <Row
                $gap={$theme.sizing.scale500}
                className={css({
                  paddingTop: $theme.sizing.scale200,
                  paddingBottom: $theme.sizing.scale200,
                  paddingLeft: $theme.sizing.scale300,
                  paddingRight: $theme.sizing.scale300,
                  ...expandBorderRadii($theme.borders.radius200),
                  backgroundColor: menuOpen ? $theme.colors.backgroundQuaternary : undefined,
                  cursor: "pointer",
                  userSelect: "none",
                  overflow: "hidden",
                  ":hover": {
                    backgroundColor: $theme.colors.backgroundQuaternary,
                  },
                })}
              >
                <div className={css({ [Breakpoint.Small]: { display: "none" } })}>
                  <BillingPlanTag billingPlanName={team.billingPlanName} />
                </div>
                <SubHeading
                  $style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {team.name}
                </SubHeading>
                <AccordionExpandIcon direction={menuOpen ? "up" : "down"} />
              </Row>
            </StatefulPopover>
          )}
          {editingTeamName ? (
            <IconButton
              label="Save team name"
              disabled={editTeamName.submitting}
              onClick={async () => {
                const result = await editTeamName.save();

                if (result) {
                  setTeam(result.team);
                }

                setEditingTeamName(false);
              }}
            >
              <CheckmarkIcon />
            </IconButton>
          ) : !props.readonly ? (
            <IconButton label="Edit team name" onClick={() => setEditingTeamName(true)}>
              <EditIcon />
            </IconButton>
          ) : null}
        </Row>
        <CreateNewTeamModal initialPlan={initialPlan} isOpen={newTeamModalOpen} onClose={() => setNewTeamModalOpen(false)} />
      </>
    );
  }
);
