import { Row, expandBorderRadii } from "@gadgetinc/widgets";
import { AccordionExpandIcon } from "@gadgetinc/widgets/src/icons/AccordionExpandIcon";
import { LogoutIcon } from "@gadgetinc/widgets/src/icons/LogoutIcon";
import { useStyletron } from "baseui";
import { StatefulMenu } from "baseui/menu";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import { LabelSmall } from "baseui/typography";
import { compact } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import Gravatar from "react-gravatar";
import type { StyleObject } from "styletron-react";

export const UserDropdown = observer((props: { userEmail: string; $style?: StyleObject }) => {
  const [css, $theme] = useStyletron();
  const [menuOpen, setMenuOpen] = useState(false);
  const rootURL = process.env.NODE_ENV === "production" ? "https://app.gadget.dev" : "https://app.ggt.dev";

  return (
    <Row
      $gap={$theme.sizing.scale200}
      className={css({
        ...props.$style,
      })}
    >
      <StatefulPopover
        onOpen={() => setMenuOpen(true)}
        onClose={() => setMenuOpen(false)}
        placement={PLACEMENT.bottomRight}
        popoverMargin={0}
        content={({ close }) => (
          <StatefulMenu
            overrides={{
              Option: {
                style: {
                  paddingTop: `calc(${$theme.sizing.scale200} - 1px)`,
                  paddingBottom: `calc(${$theme.sizing.scale200} - 1px)`,
                  color: $theme.colors.primaryA,
                },
              },
            }}
            items={compact([
              {
                label: (
                  <Row $gap={$theme.sizing.scale400}>
                    <LogoutIcon />
                    Logout
                  </Row>
                ),
                onSelect: () => (window.location.href = `${rootURL}/auth/logout`),
              },
            ])}
            onItemSelect={({ item }) => {
              item.onSelect();
            }}
          />
        )}
      >
        <Row
          $gap={$theme.sizing.scale500}
          className={css({
            paddingTop: $theme.sizing.scale200,
            paddingBottom: $theme.sizing.scale200,
            paddingLeft: $theme.sizing.scale300,
            paddingRight: $theme.sizing.scale300,
            ...expandBorderRadii($theme.borders.radius200),
            backgroundColor: menuOpen ? $theme.colors.backgroundQuaternary : undefined,
            cursor: "pointer",
            userSelect: "none",
            overflow: "hidden",
            ":hover": {
              backgroundColor: $theme.colors.backgroundQuaternary,
            },
          })}
        >
          <Gravatar
            email={props.userEmail}
            size={16}
            rating="pg"
            default="identicon"
            className={css({
              ...expandBorderRadii("50%"),
            })}
          />
          <LabelSmall
            $style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {props.userEmail}
          </LabelSmall>
          <AccordionExpandIcon direction={menuOpen ? "up" : "down"} />
        </Row>
      </StatefulPopover>
    </Row>
  );
});
