import type { Theme } from "baseui/theme";
import { merge, omit } from "lodash";
import React, { forwardRef } from "react";
import { ExternalLinkIcon } from "../../icons/ExternalLinkIcon";
import type { ButtonProps } from "./Button";
import { Button, ButtonKind, ButtonSize } from "./Button";

export type LinkProps = { href: string; rel?: string; external?: boolean; compact?: boolean } & Omit<ButtonProps, "ref">;

const base =
  typeof window !== "undefined" ? new URL(`${window.location.protocol}//${window.location.host}`) : new URL("https://app.gadget.dev");

export function isInternalURL(url: string) {
  try {
    return new URL(url, base).hostname === base.hostname;
  } catch (error) {
    return true;
  }
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const internal = props.external ? false : isInternalURL(props.href);

  const overrides = merge(
    {
      BaseButton: {
        style: ({ $theme }: { $theme: Theme }) => {
          return {
            paddingLeft: "0px",
            paddingRight: "0px",
            paddingTop: props.compact ? "0px" : undefined,
            paddingBottom: props.compact ? "0px" : undefined,
            fontSize: "inherit",
            fontWeight: "inherit",
            fontFamily: "inherit",
            whiteSpace: "no-wrap", // because buttons are inline-flex, they overflow their parents width sometimes, this ensures the parent grows to contain them
            color: "inherit",
            textDecoration: "underline",
            ":hover:not(:disabled)": {
              color: "inherit",
            },
            ":hover:not(:disabled):not(:active)": {
              color: "inherit",
            },
            ":focus-visible": {
              color: "inherit",
              outlineOffset: "3px",
            },
            ":disabled": {
              color: $theme.colors.alpha400,
              backgroundColor: "transparent",
            },
            ":active:not(:disabled)": {
              color: "inherit",
              // This is a hack to disable the button's default transform because we set the button as <a/> and it doesn't have the "disabled" HTML attribute
              transform: props.disabled ? "" : "perspective(500px) translate3d(0, 0, -20px)",
            },
          };
        },
      },
      EndEnhancer: {
        style: {
          marginLeft: "4px",
        },
      },
    },
    props.overrides
  );

  return (
    <Button
      ref={ref as any}
      kind={ButtonKind.tertiary}
      $as="a"
      target={internal ? undefined : "_blank"}
      endEnhancer={props.endEnhancer ?? internal ? null : <ExternalLinkIcon $size={props.size == ButtonSize.compact ? 12 : 16} />}
      {...omit(props, ["compact"])}
      overrides={overrides}
    />
  );
});
