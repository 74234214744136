import type { GadgetColors } from "./EditTheme";
import type { PrimitiveTypography } from "./utils";

export const gadgetColorsLight = {
  neutralMin: "#FFF",
  neutralMax: "#000",

  primary50: "#F6F6F6",
  primary100: "#EEEEEE",
  primary200: "#E2E2E2",
  primary300: "#CBCBCB",
  primary400: "#AFAFAF",
  primary500: "#757575",
  primary600: "#545454",
  primary700: "#333333",

  alpha0: "rgba(0, 0, 0, 0)",
  alpha50: "rgba(0, 0, 0, 0.03)",
  alpha100: "rgba(0, 0, 0, 0.07)",
  alpha200: "rgba(0, 0, 0, 0.11)",
  alpha300: "rgba(0, 0, 0, 0.2)",
  alpha400: "rgba(0, 0, 0, 0.31)",
  alpha500: "rgba(0, 0, 0, 0.54)",
  alpha600: "rgba(0, 0, 0, 0.67)",
  alpha700: "rgba(0, 0, 0, 0.8)",
  alpha800: "rgba(0, 0, 0, 0.9)",
  alpha900: "rgba(0, 0, 0, 0.95)",

  electricBlue50: "#ECECFF",
  electricBlue100: "#D9D9FE",
  electricBlue200: "#B3B3FE",
  electricBlue300: "#8E8EFD",
  electricBlue400: "#6868FD",
  electricBlue500: "#4242FC",
  electricBlue600: "#3535CE",
  electricBlue700: "#2828A0",

  yellow50: "#FFF8EC",
  yellow100: "#FDEDD6",
  yellow200: "#FBE6BC",
  yellow300: "#FACF8F",
  yellow400: "#FCBC63",
  yellow500: "#DC892A",
  yellow600: "#A66822",
  yellow700: "#6F4616",

  red50: "#FFEDEB",
  red100: "#FFDBD7",
  red200: "#F7AEA6",
  red300: "#F27769",
  red400: "#DB3927",
  red500: "#B13325",
  red600: "#8C2B20",
  red700: "#6D1F17",

  green50: "#F0F9F4",
  green100: "#DAF0E3",
  green200: "#AEDDC2",
  green300: "#73C496",
  green400: "#3AA76D",
  green500: "#368759",
  green600: "#2B6B46",
  green700: "#1C472F",

  blue50: "#F0F3FD",
  blue100: "#D7E2FA",
  blue200: "#A6BEF3",
  blue300: "#6790EE",
  blue350: "#007BFF",
  blue400: "#3B6CE9",
  blue500: "#2D53B1",
  blue600: "#23418C",
  blue700: "#172B5D",

  cyan50: "#EFF8FF",
  cyan100: "#D2EFFF",
  cyan200: "#B6E5FF",
  cyan300: "#92D7FE",
  cyan400: "#63C7FF",
  cyan500: "#3A9BD1",
  cyan600: "#3383AF",
  cyan700: "#1B5B80",

  purple50: "#F7F5FD",
  purple100: "#F1EBFF",
  purple200: "#DBCBFF",
  purple300: "#BEA1FF",
  purple400: "#9E72FE",
  purple500: "#6F4BCD",
  purple600: "#5B38B6",
  purple700: "#3B227A",

  purpleAlpha50: "rgba(62, 14, 169, 0.04)",
  purpleAlpha100: "#4F00FF14",
  purpleAlpha200: "#5407FE36",
  purpleAlpha300: "#5407FE61",
  purpleAlpha400: "#5407FE91",
  purpleAlpha500: "#4208C2BF",
  purpleAlpha600: "#3500ABCC",
  purpleAlpha700: "#210066DE",

  gellyPurple: "#9B50D7",
  development: "#404B5A",
  disabledDevelopment: "#404B5AB2",
  backgroundDevelopment: "rgba(162, 185, 215, 0.21)",

  inputAlpha: "rgba(0, 0, 0, 0.01)",
  splashIllustrationBackground: "#F8F8FE",
  backgroundEnvironmentSettingsCalloutDevelopment: "#EBF0F6",
  contentEnvironmentSettingsCalloutDevelopment: "rgba(64, 75, 90, 0.60)",
  backgroundEnvironmentSettingsCalloutProduction: "#F2EDFD",
  backgroundProduction: "#C5ADFA36",
  contentEnvironmentSettingsCalloutProduction: "#5634AE99",
} as const;

export const gadgetColorsDark: GadgetColors = {
  neutralMin: "#000",
  neutralMax: "#FFF",

  primary50: "#333333",
  primary100: "#545454",
  primary200: "#757575",
  primary300: "#AFAFAF",
  primary400: "#CBCBCB",
  primary500: "#E2E2E2",
  primary600: "#EEEEEE",
  primary700: "#F6F6F6",

  alpha0: "rgba(255, 255, 255, 0)",
  alpha50: "rgba(255, 255, 255, 0.03)",
  alpha100: "rgba(255, 255, 255, 0.07)",
  alpha200: "rgba(255, 255, 255, 0.11)",
  alpha300: "rgba(255, 255, 255, 0.2)",
  alpha400: "rgba(255, 255, 255, 0.31)",
  alpha500: "rgba(255, 255, 255, 0.54)",
  alpha600: "rgba(255, 255, 255, 0.67)",
  alpha700: "rgba(255, 255, 255, 0.8)",
  alpha800: "rgba(255, 255, 255, 0.9)",
  alpha900: "rgba(255, 255, 255, 0.95)",

  electricBlue50: "#2828A0",
  electricBlue100: "#3535CE",
  electricBlue200: "#4242FC",
  electricBlue300: "#6868FD",
  electricBlue400: "#8E8EFD",
  electricBlue500: "#B3B3FE",
  electricBlue600: "#D9D9FE",
  electricBlue700: "#ECECFF",

  yellow50: "#6F4616",
  yellow100: "#A66822",
  yellow200: "#DC892A",
  yellow300: "#FCBC63",
  yellow400: "#FACF8F",
  yellow500: "#FBE6BC",
  yellow600: "#FDEDD6",
  yellow700: "#FFF8EC",

  red50: "#6D1F17",
  red100: "#8C2B20",
  red200: "#B13325",
  red300: "#DB3927",
  red400: "#F27769",
  red500: "#F7AEA6",
  red600: "#FFDBD7",
  red700: "#FFEDEB",

  green50: "#1C472F",
  green100: "#2B6B46",
  green200: "#368759",
  green300: "#3AA76D",
  green400: "#73C496",
  green500: "#AEDDC2",
  green600: "#DAF0E3",
  green700: "#F0F9F4",

  blue50: "#172B5D",
  blue100: "#23418C",
  blue200: "#2D53B1",
  blue300: "#3B6CE9",
  blue350: "#007BFF",
  blue400: "#6790EE",
  blue500: "#A6BEF3",
  blue600: "#D7E2FA",
  blue700: "#F0F3FD",

  cyan50: "#1B5B80",
  cyan100: "#3383AF",
  cyan200: "#3A9BD1",
  cyan300: "#63C7FF",
  cyan400: "#92D7FE",
  cyan500: "#B6E5FF",
  cyan600: "#D2EFFF",
  cyan700: "#EFF8FF",

  purple50: "#261650",
  purple100: "#361D77",
  purple200: "#5D39BB",
  purple300: "#7340E4",
  purple400: "#9168EC",
  purple500: "#BFA8F2",
  purple600: "#E3D7FD",
  purple700: "#F5F2FC",

  purpleAlpha50: "#210066DE",
  purpleAlpha100: "#3500ABCC",
  purpleAlpha200: "#4208C2BF",
  purpleAlpha300: "#5407FE91",
  purpleAlpha400: "#5407FE61",
  purpleAlpha500: "#5407FE36",
  purpleAlpha600: "#4F00FF14",
  purpleAlpha700: "rgba(62, 14, 169, 0.04)",

  gellyPurple: "#8d3ccf",
  development: "#404B5A",
  disabledDevelopment: "#404B5AB2",
  backgroundDevelopment: "rgba(162, 185, 215, 0.21)",

  inputAlpha: "rgba(0, 0, 0, 0.01)",
  splashIllustrationBackground: "#F8F8FE",
  backgroundEnvironmentSettingsCalloutDevelopment: "#EBF0F6",
  contentEnvironmentSettingsCalloutDevelopment: "rgba(64, 75, 90, 0.60)",
  backgroundEnvironmentSettingsCalloutProduction: "#F2EDFD",
  backgroundProduction: "#C5ADFA36",
  contentEnvironmentSettingsCalloutProduction: "#5634AE99",
};

export const gadgetShadows = {
  selected: "0px 2px 0px 1px rgb(66, 66, 252, 0.3)",
  depth2: "0px 3px 2px -1px rgba(0, 0, 0, 0.12)",
  depth3: "0px 8px 16px -4px rgba(0, 0, 0, 0.24)",
  depth4: "0px 12px 20px -8px rgba(0, 0, 0, 0.32)",
  shadowButton: "0px -1px 0px 0px rgba(0, 0, 0, 0.24) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.16)",
  shadowButtonPrimary: "0px -2px 0px 0px rgba(0, 0, 0, 0.24) inset, 0px 1px 1px 0px rgba(0, 0, 0, 0.16)",
  shadowButtonInset:
    "0px 2px 2px 0px rgba(0, 0, 0, 0.16) inset, 1px 0px 1px 0px rgba(0, 0, 0, 0.08) inset, -1px 0px 1px 0px rgba(0, 0, 0, 0.08) inset",
  shadowButtonPrimaryInset:
    "0px 2px 2px 0px rgba(0, 0, 0, 0.32) inset, 1px 0px 1px 0px rgba(0, 0, 0, 0.16) inset, -1px 0px 1px 0px rgba(0, 0, 0, 0.16) inset",
  shadowInputInset: "0px 1px 1px 0px rgba(0, 0, 0, 0.08) inset",
} as const;

export const productTypography: PrimitiveTypography = {
  title: {
    fontFamily: "Inconsolata",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "30px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  navTitle: {
    fontFamily: "Inconsolata",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "1px",
    textAlign: "left",
  },
  heading: {
    fontFamily: "Inconsolata",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  subHeading: {
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  body: {
    fontFamily: "Inconsolata",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  caption: {
    fontFamily: "Inconsolata",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "16px",
    letterSpacing: "0px",
    textAlign: "left",
  },
  contentLink: {
    textDecoration: "none",
  },
} as const;

export const getPrimitiveColors = (palette: GadgetColors) =>
  ({
    accent: palette.electricBlue500,
    accent50: palette.purple50,
    accent100: palette.purple100,
    accent200: palette.purple200,
    accent300: palette.purple300,
    accent400: palette.purple400,
    accent500: palette.purple500,
    accent600: palette.purple600,
    accent700: palette.purple700,

    negative50: palette.red50,
    negative100: palette.red100,
    negative200: palette.red200,
    negative300: palette.red300,
    negative400: palette.red400,
    negative500: palette.red500,
    negative600: palette.red600,
    negative700: palette.red700,

    warning50: palette.yellow50,
    warning100: palette.yellow100,
    warning200: palette.yellow200,
    warning300: palette.yellow300,
    warning400: palette.yellow400,
    warning500: palette.yellow500,
    warning600: palette.yellow600,
    warning700: palette.yellow700,

    positive50: palette.green50,
    positive100: palette.green100,
    positive200: palette.green200,
    positive300: palette.green300,
    positive400: palette.green400,
    positive500: palette.green500,
    positive600: palette.green600,
    positive700: palette.green700,

    primary50: palette.primary50,
    primary100: palette.primary100,
    primary200: palette.primary200,
    primary300: palette.primary300,
    primary400: palette.primary400,
    primary500: palette.primary500,
    primary600: palette.primary600,
    primary700: palette.primary700,
  } as const);

export const space = {
  scale0: "2px",
  scale100: "4px",
  scale200: "6px",
  scale300: "8px",
  scale400: "10px",
  scale500: "12px",
  scale550: "14px",
  scale600: "16px",
  scale650: "18px",
  scale700: "20px",
  scale750: "22px",
  scale800: "24px",
  scale850: "28px",
  scale900: "32px",
  scale950: "36px",
  scale1000: "40px",
  scale1200: "48px",
  scale1400: "56px",
  scale1600: "64px",
  scale2400: "96px",
  scale3200: "128px",
  scale4800: "192px",
} as const;

export const radii = {
  radius100: "2px",
  radius200: "4px",
  radius300: "8px",
  radius400: "12px",
};
